import pathToRegexp from 'path-to-regexp';
import Loadable from '../components/Loading';
function getFlatMenuData(menus) {
  let keys = {};
  menus.forEach((item) => {
    if (item.children && item.children.length > 0) {
      keys[item.path] = {
        ...item,
      };
      keys = {
        ...keys,
        ...getFlatMenuData(item.children),
      };
    } else {
      keys[item.path] = {
        ...item,
      };
    }
  });
  return keys;
}
// 打开路由时，设置正确的菜单项的key高亮值；主要针对访问二级路 由页面时，设置正确的左侧菜单项高亮值；
export const menuOpenKeyAncestorMap = {};

// 设置菜单项的默认跳转路由，例如：打开固件，则实际跳转到firmware/list页面，主要针对左侧菜单项只有一条高亮，而内容页面会细分二级菜单项
export const redirectRoutes = [
  {
    from: '/system',
    to: '/system/account',
  },
  {
    from: '/community',
    to: '/community/topic',
  },
];
export const getRouterData = (permissionsMenuData) => {
  const routerConfig = {
    '/': {
      component: Loadable({
        loader: () => import('../layouts/BasicLayout'),
      }),
    },
    '/dashboard/user_new': {
      component: Loadable({
        loader: () => import('../containers/Dashboard/UserAdd'),
      }),
    },
    // 3月29日增加数据后台路由
    '/dashboard/preserve': {
      component: Loadable({
        loader: () => import('../containers/Dashboard/KeepTheUser'),
      }),
    },
    '/dashboard/starttheAnalysis': {
      component: Loadable({
        loader: () => import('../containers/Dashboard/StarttheAnalysis'),
      }),
    },
    '/dashboard/channel': {
      component: Loadable({
        loader: () => import('../containers/Dashboard/ChannelAnalysis'),
      }),
    },

    '/dashboard/user_active': {
      component: Loadable({
        loader: () => import('../containers/Dashboard/UserActive'),
      }),
    },

    // 课程分析路由
    '/courseanalysis/degree': {
      component: Loadable({
        loader: () => import('../containers/Courseanalysis/Frequency'),
      }),
    },
    '/courseanalysis/timeframe': {
      component: Loadable({
        loader: () => import('../containers/Courseanalysis/TimeFrame'),
      }),
    },
    '/courseanalysis/duration': {
      component: Loadable({
        loader: () => import('../containers/Courseanalysis/Durationanalysis'),
      }),
    },
    // 设备分析路由
    '/equipment/generalization': {
      component: Loadable({
        loader: () => import('../containers/Equipment/Generalization'),
      }),
    },
    '/equipment/usageanalysis': {
      component: Loadable({
        loader: () => import('../containers/Equipment/UsageAnalysis'),
      }),
    },
    '/equipment/gameanalysis': {
      component: Loadable({
        loader: () => import('../containers/Equipment/Gameanalysis'),
      }),
    },
    // 商品分析
    '/commodityanalysis': {
      component: Loadable({
        loader: () => import('../containers/Commodityanalysis'),
      }),
    },
    // 综合用户属性
    '/userattribute/synthesizedattribute': {
      component: Loadable({
        loader: () => import('../containers/Userattribute/Synthesizedattribute'),
      }),
    },
    // 奥迈体新增用户表单  3月3日增加
    '/Aomaiti': {
      component: Loadable({
        loader: () => import('../containers/Aomaiti'),
      }),
    },
    '/lingdong/shebei/list': {
      component: Loadable({
        loader: () => import('../containers/Lingdong/shebei'),
      }),
    },
    '/lingdong/userlist/list': {
      component: Loadable({
        loader: () => import('../containers/Lingdong/User'),
      }),
    },
    '/lingdong/workout': {
      component: Loadable({
        loader: () => import('../containers/Lingdong/Workout'),
      }),
    },
    '/lingdong/mall': {
      component: Loadable({
        loader: () => import('../containers/Lingdong/Mall'),
      }),
    },

    '/resource': {
      component: Loadable({
        loader: () => import('../containers/Resource'),
      }),
    },
    '/device/list': {
      component: Loadable({
        loader: () => import('../containers/Devices'),
      }),
    },
    '/device/model': {
      component: Loadable({
        loader: () => import('../containers/Devices/Model'),
      }),
    },
  //  课程页面
    '/workout/course': {
      component: Loadable({
        loader: () => import('../containers/Workout/Course'),
      }),
    },
    // 名师页面
    '/workout/topteacher': {
      component: Loadable({
        loader: () => import('../containers/Workout/TopTeacher'),
      }),
    },
    '/activity': {
      component: Loadable({
        loader: () => import('../containers/Activity'),
      }),
    },
    '/medal': {
      component: Loadable({
        loader: () => import('../containers/Medal'),
      }),
    },
    '/community/topic': {
      component: Loadable({
        loader: () => import('../containers/Community/Topic'),
      }),
    },
    '/community/topic-type': {
      component: Loadable({
        loader: () => import('../containers/Community/TopicType'),
      }),
    },
    '/community/moments': {
      component: Loadable({
        loader: () => import('../containers/Community/Moments'),
      }),
    },
    '/reports': {
      component: Loadable({
        loader: () => import('../containers/Reports'),
      }),
    },
    '/mall': {
      component: Loadable({
        loader: () => import('../containers/Mall'),
      }),
    },
    '/game': {
      component: Loadable({
        loader: () => import('../containers/Game'),
      }),
    },
    // 积分页面配置：  10月18日修改
    '/Integral': {
      component: Loadable({
        loader: () => import('../containers/Integral'),
      }),
    },
    // 抽奖页面配置：  10月25日修改
    '/Luckdraw': {
      component: Loadable({
        loader: () => import('../containers/Luckdraw'),
      }),
    },
    '/user/list': {
      component: Loadable({
        loader: () => import('../containers/User'),
      }),
    },
    // 1月11号新增达人管理路由
    '/user/TalentManagement': {
      component: Loadable({
        loader: () => import('../containers/User/TalentManagement'),
      }),
    },

     //3月24号修改营销管理路由
     '/management/push': {
      component: Loadable({
        loader: () => import('../containers/management/Push'),
      }),
    },
    '/management/featuredFirst': {
      component: Loadable({
        loader: () => import('../containers/management/FeaturedFirst'),
      }),
    },

    '/Exercise/list': {
      component: Loadable({
        loader: () => import('../containers/Exercise'),
      }),
    },
    
    '/software': {
      component: Loadable({
        loader: () => import('../containers/Software'),
      }),
    },
    '/config': {
      component: Loadable({
        loader: () => import('../containers/Config'),
      }),
    },
    '/system/menus': {
      component: Loadable({
        loader: () => import('../containers/System/Menus'),
      }),
    },
    '/system/roles': {
      component: Loadable({
        loader: () => import('../containers/System/Roles'),
      }),
    },
    '/system/dictionaries': {
      component: Loadable({
        loader: () => import('../containers/System/Dictionaries'),
      }),
    },
    '/system/permission': {
      component: Loadable({
        loader: () => import('../containers/System/Permissions'),
      }),
    },
    '/system/account': {
      component: Loadable({
        loader: () => import('../containers/System/Accounts'),
      }),
    },
    '/login': {
      component: Loadable({
        loader: () => import('../layouts/LoginLayout'),
      }),
    },
    '/workout1': {
      component: Loadable({
        loader: () => import('../containers/Workout1'),
      }),
    },
    '/questionnaire/list': {
      component: Loadable({
        loader: () => import('../containers/questionnaire'),
      }),
    },
    '/questionnaire/subjects': {
      component: Loadable({
        loader: () => import('../containers/Subject'),
      }),
    },
    '/questionnaire/detail/:id': {
      component: Loadable({
        loader: () => import('../containers/questionnaire/Detail'),
      }),
    },
    '/store/storemanage': {
      component: Loadable({
        loader: () => import('../containers/Store/storemanage'),
      }),
    },
    '/store/list': {
      component: Loadable({
        loader: () => import('../containers/Store'),
      }),
    },
    '/employee/list': {
      component: Loadable({
        loader: () => import('../containers/Employee'),
      }),
    },
    // 方案建议，运动方案
    '/question/list': {
      component: Loadable({
        loader: () => import('../containers/question'),
      }),
    },
    '/question/health': {
      component: Loadable({
        loader: () => import('../containers/question/health'),
      }),
    },
    '/question/subjects': {
      component: Loadable({
        loader: () => import('../containers/Subject'),
      }),
    },
  };
  // Get name from ./menu.js or just set it in the router data.
  const menuData = permissionsMenuData ? getFlatMenuData(permissionsMenuData) : [];

  // Route configuration data
  // eg. {name,authority ...routerConfig }
  const routerData = {};
  // The route matches the menu
  Object.keys(routerConfig).forEach((path) => {
    // Regular match item name
    // eg.  router /user/:id === /user/chen
    const pathRegexp = pathToRegexp(path);
    const menuKey = Object.keys(menuData).find((key) => pathRegexp.test(`${key}`));

    let menuItem = {};
    // If menuKey is not empty
    if (menuKey) {
      menuItem = menuData[menuKey];
    }
    let router = routerConfig[path];
    // If you need to configure complex parameter routing,
    // https://github.com/ant-design/ant-design-pro-site/blob/master/docs/router-and-nav.md#%E5%B8%A6%E5%8F%82%E6%95%B0%E7%9A%84%E8%B7%AF%E7%94%B1%E8%8F%9C%E5%8D%95
    // eg . /list/:type/user/info/:id
    router = {
      ...router,
      name: router.name || menuItem.name,
      authority: permissionsMenuData && menuKey ? true : false,
      // hideInBreadcrumb: router.hideInBreadcrumb || menuItem.hideInBreadcrumb
    };
    routerData[path] = router;
  });
  return routerData;
};
